.flex-total-price {
  font-size: 12px !important;
  font-style: italic;
}

@media screen and (min-width: 480px) {
  .flex-total-price {
    font-size: 9px !important;
  }
}

@media screen and (min-width: 520px) {
  .flex-total-price {
    font-size: 10px !important;
  }
}

@media screen and (min-width: 1300px) {
  .flex-total-price {
    font-size: 12px !important;
  }
}