@import "./_cms-critical";
@import "./Components/FilterButtonsCms/_FilterButtons.scss";

.campaign-content {
    margin-bottom: 2em;

    .row {
        margin-left: 0;
        margin-right: 0;

        @media (min-width: $screen-sm-min) {
            margin-left: -$small-gutter;
            margin-right: -$small-gutter;
        }

        display: flex;
        flex-direction: row;
        align-items: stretch;
        flex-wrap: wrap;

        &:before,
        &:after {
            content: none;
        }

        [class*="col-"] {
            padding-left: $small-gutter / 1.5;
            padding-right: $small-gutter / 1.5;
            margin-top: $small-gutter / 1.5;
            margin-bottom: $small-gutter / 1.5;

            @media (min-width: $screen-sm-min) {
                padding-left: $small-gutter;
                padding-right: $small-gutter;
                margin-top: $small-gutter;
                margin-bottom: $small-gutter;
            }

            @media (max-width: $screen-sm-min) {
                padding-left: $small-gutter / 1.5 !important;
                padding-right: $small-gutter / 1.5 !important;
            }

            display: flex;
            align-items: stretch;
            flex-wrap: wrap;
            float: none;
            flex: 1 0 auto;
        }

        .swiper-container {
            height: 100%;
            width: 100%;
        }
    }

    &.seamless {
        .row {
            margin-left: 0;
            margin-right: 0;

            @media (min-width: $screen-md-min) {
                margin-left: 0;
                margin-right: 0;
            }

            [class*="col-"] {
                padding-left: 0;
                padding-right: 0;
                margin-top: 0;
                margin-bottom: 0;

                @media (min-width: $screen-md-min) {
                    padding-left: 0;
                    padding-right: 0;
                    margin-top: 0;
                    margin-bottom: 0;
                }

                @media (max-width: $screen-sm-min) {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
            }
        }
    }

    .best-seller {
        width: 100%;
    }

    .product-list {
        width: 100%;
        
        .product-list-item .box-bottom {
            justify-content: flex-start;

            @media (min-width: $screen-xs-min) {
                .price-wrapper {
                    position: static;
                }                
            }
        }
    }

    .top-list {
        width: 100%;
    }

    .toplist-buttons-group {
        width: 100%;

        .group-dropdown {
            width: 100%;
        }
    }

    .loading-overlay {
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 140;
        border-radius: 5px;
        transition: background-color 0.2s linear 0s;
        background-color: transparent;
    }

    .loading-overlay[data-is-loading="true"] {
        visibility: visible;
        transition: background-color 0.5s linear;
        background-color: rgba(255, 255, 255, 0.7);
    }

    @import "ControlPartialViews/_RichTextEditor";
    @import "ControlPartialViews/_media";
    @import "../BannerImage/_BannerImage";
    @import "Components/BannerCarousel/_BannerCarousel";
    @import "ControlPartialViews/_RichRecommendations";
    @import "ControlPartialViews/_iframe";
    @import "Components/SocialMedia/_SocialMediaComponent";
    @import "../VideoCms/_video";
    @import "Components/Newsletter/_NewsLetter";
    @import "Components/ArticleBanner/_articleBanner";
    @import "Components/ExpandableBlock/_expandableBlock.scss";
    @import "Components/ExpandableRte/_expandableRte.scss";
    @import "Components/IconFilterButtons/_IconFilterButtonsCms.scss";
    @import "../RichNavigation/_richNavigation.scss";
    @import "Components/WebGame/_WebGameComponent";
    @import "ControlPartialViews/_ProductList.scss";
}
