@use "sass:math";
$icon-size: 16px !default;

.product-list-item {
  @extend %flexbox-row;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  align-items: stretch;
  border-bottom: 1px solid $border-color;
  background-color: #fff;

  &:hover,
  &:focus {
    @media (min-width: $screen-md-min) {
      box-shadow: inset 0 0 0 3px $border-color;
    }
  }

  &:active {
    @media (max-width: $screen-sm-max) {
      box-shadow: inset 0 0 0 3px $border-color;
    }
  }

  .product-link {
    color: $text-color-default;
    text-decoration: none;

    &:hover,
    &:focus,
    &:hover:active {
      color: $text-color-default;
      text-decoration: none;
    }
  }

  .image-container {
    flex: 0 0 auto;
    width: 150px;
    display: flex;
    align-items: center;
    padding: $product-box-spacing * 2 0 $product-box-spacing * 2
      $product-box-spacing;
    position: relative;

    @media (max-width: $screen-xs-max) {
      @supports (-webkit-line-clamp: 3) {
        // additional space for .stockstatus on small screens
        margin: $product-box-spacing * 2 0 $product-box-spacing * 6
          $product-box-spacing;
        padding: 0;
      }
    }

    .image-wrapper {
      width: 100%;
      padding: 0 0 $icon-size + 1;
    }

    .product-image {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
      transition: opacity 0.5s;

      &.product-image__loading {
        opacity: 0;
      }
    }

    .discount-bubble {
      position: absolute;
      top: 50%;
      font-size: 50px;
      margin-top: -25px;
      left: math.div($product-box-spacing, 2);
      z-index: 130;

      @media (max-width: $screen-sm-max) {
        font-size: 32px;
      }

      .discount-label {
        font-size: 16px;

        @media (max-width: $screen-sm-max) {
          font-size: 11px;
        }
      }
    }

    .flex-discount-bubble {
      position: absolute;
      top: 50%;
      margin-top: -25px;
      left: math.div($product-box-spacing, 2);
      z-index: 130;
      background-color: #1f1949;
      border-radius: 50%;
      font-size: 3.5em;

      width: 1.25em;
      height: 1.25em;
      color: #fff;
      text-align: center;

      @media (max-width: $screen-sm-max) {
        width: 1em;
        height: 1em;
      }

      .flex-discount-label {
        font-size: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        white-space: nowrap;
        vertical-align: middle;

        @media (max-width: $screen-sm-max) {
          font-size: 11px;
          margin: 0;
          padding: 0;
          text-align: center;
        }

        .flex-discount-label-title{
          font-style: italic;
          font-weight: bold;
          font-size: 12px;
          @media (max-width: $screen-sm-max) {
            font-size: 9px;
          }
        }

        .flex-discount-label-percentage{
          font-weight: bold;
          font-size: 16px;
        }
      }
    }

    .flex-discount-rectangle {
      position: absolute;
      padding: 0 0 0 20px;
      left: 0;
      top: 20%;
      background-color: $price-bomb-font-color;
      color: #FFFFFF;

      .flex-rectangle-upper {
        margin: 0;
        display: inline;
        padding-right: 10px;

        .flex-rectangle-upper-percentage{
          font-weight: bold;
          text-align: center;
          font-size: 20px;
        }

        .flex-rectangle-upper-text{
          text-align: center;
        }
      }

      .flex-rectangle-ruler {
        margin: 0;
      }

      .flex-rectangle-lower {
        font-style: italic;
      }
    }

    .sticker {
      position: absolute;
      max-width: 50%;

      &.sticker {
        &-top-right {
          top: math.div($product-box-spacing, 2);
          right: math.div($product-box-spacing, 2);
        }

        &-top-left {
          top: math.div($product-box-spacing, 2);
          left: math.div($product-box-spacing, 2);
        }

        &-bottom-right {
          bottom: math.div($product-box-spacing, 2);
          right: math.div($product-box-spacing, 2);
        }

        &-bottom-left {
          bottom: math.div($product-box-spacing, 2);
          left: math.div($product-box-spacing, 2);
        }

        &-middle-right {
          right: math.div($product-box-spacing, 2);
          transform: translate(0, -50%);
          top: 50%;
        }
        &-middle-left {
          left: math.div($product-box-spacing, 2);
          transform: translate(0, -50%);
          top: 50%;
        }
      }

      @media (min-width: $screen-sm-min) {
        max-width: none;
      }
    }
  }

  .content-block {
    flex: 1 1 auto;
    padding: $product-box-spacing * 2 $product-box-spacing;
    @extend %flexbox-column;
    justify-content: space-between;
    word-wrap: break-word;
    width: 100%;
  }

  .text-container {
    display: block;
    padding: 0 $icon-size + 2 $product-box-spacing 0;
    order: -1;
    word-break: break-word;

    &:focus,
    &:active {
      outline: 0;

      &::before {
        content: "";
        position: absolute;
        display: inline;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        /* stylelint-disable declaration-block-no-duplicate-properties */
        outline: thin dotted;
        outline: 5px auto -webkit-focus-ring-color;
        /* stylelint-enable */
        outline-offset: -2px;
        z-index: -1; // To set the hidden "layer" in the background, that way we can Ctrl+click/middleclick the link to get it in new tab
      }
    }

    h2 {
      font-size: 14px;
      font-weight: bold;
      margin: 0 0 math.div($product-box-spacing, 2) 0;
    }

    p {
      margin: 0 0 math.div($product-box-spacing, 2) 0;
      font-size: 12px;
    }

    .text-content {
      order: -1;
    }

    .review-data {
      order: 1;
    }
  }

  .ellipsis {
    display: inline;

    @media (min-width: $screen-xs-min) {
      display: none;
    }

    &:after {
      content: "...";
      display: inline-block;
    }
  }

  .extended-description {
    display: none;

    @media (min-width: $screen-xs-min) {
      display: inline;
    }
  }

  .box-bottom {
    @extend %flexbox-row;
    order: 1;
    flex-wrap: wrap;

    .energy-logo {
      width: 100%;
    }

    .product-energy-logo {
      display: none;
    }
  }

  .review {
    margin-top: math.div($product-box-spacing, 2);
    margin-bottom: math.div($product-box-spacing, 2);

    .rating-stars {
      @extend .icon-sm;
      vertical-align: text-top;
    }
  }

  .delivery {
    display: none;

    @media (min-width: $screen-sm-min) {
      display: block;
    }
  }

  .stockstatus {
    font-size: 12px;
    padding: 0;
    width: 90%;
    line-height: 20px;
    height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .delivery {
    position: absolute;
    font-size: 12px;
    bottom: 7px;
    padding: 0 0 0 $product-box-spacing;
    width: 145px;
    text-align: center;
    left: 0;
    line-height: 20px;
    height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .stockstatus {
    @media (max-width: $screen-xs-max) {
      @supports (-webkit-line-clamp: 3) {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    .stockstatus-availability-label {
      display: none;
    }

    .stockstatus-stock-details {
      display: inline;
      font-size: 12px;
      line-height: 1em;
    }
  }

  .product-data {
    display: none;
    font-size: 12px;
  }

  .price-wrapper {
    display: flex;
    flex-direction: column;

    @media (max-width: $screen-xs-max) {
      position: absolute;
      bottom: 18px;
      left: 18px;
    }

    .product-price {
      line-height: 1em;

      .product-price-now,
      .product-price-now-label {
        font-size: 20px;
      }
    }

    .product-price-before {
      font-size: 12px;
      display: block;
      padding: math.div($product-box-spacing, 2) 0
        math.div($product-box-spacing, 2);
      color: #666666;
    }

    .product-price-subscription {
      font-size: 12px;
      padding-top: math.div($product-box-spacing, 2);
      margin-bottom: -4px;

      &-flex {
        font-style: italic;
        margin-right: 2px;
      }
    }

    .product-price-exsklmva {
      font-weight: normal;
      font-size: 10px;
    }
  }

  .buy-button,
  .subscription-buy-button,
  .subscribe-product__redirect,
  .price-on-request-button__container {
    display: flex;
    margin-left: 0;

    &.align-right {
      align-self: flex-end;
    }

    .btn-small {
      border-bottom: 0;
      min-width: 85px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      text-transform: none;

      &:before {
        box-shadow: none;
      }
    }
  }

  .buy-button {

    .subscription-buy-button {
      background: #1f1949;
      color: #FFFFFF;
      padding-left: 8px !important;
      padding-right: 8px !important;
      border-bottom-color: #9b99ae;
      transition: background .2s ease-in-out;

      &:hover {
        background: #312B5F;
      }
    }

    .btn-small {
      .icon {
        margin-right: 5px;
      }

      .checkmark {
        position: static;
      }

      &.added-to-cart, &.added-to-cart:active, &.added-to-cart:hover, &.added-to-cart:focus, &.added-to-cart:focus:active {
        .text {
          margin-left: 5px;
        }
      }
    }

    .quantity-picker {
      display: none;

      label {
        margin-bottom: 0;
      }
    }
  }

  .product-energy-logo {
    width: 100%;
    @extend %flexbox-row;
    align-items: center;
    font-size: 12px;
    flex-wrap: wrap;
    flex-direction: row;
    min-height: 0;
    margin-top: $product-box-spacing;

    .product-energy-logo-details {
      margin-left: math.div($product-box-spacing, 2);
      color: #0670a3;
    }
  }

  .comparison-checkbox {
    position: absolute;
    right: $product-box-spacing;
    top: $product-box-spacing * 2;
    z-index: 3;

    .checkbox-set {
      .input {
        line-height: 1em;

        label {
          margin-bottom: 0;
          font-size: 14px;
          line-height: 16px;

          span {
            display: none;
          }
        }
      }

      input[type="checkbox"] {
        & + label::before {
          margin-right: 0;
        }
      }
    }

    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }

  .crm-only,
  .crm-eol {
    position: absolute;
    z-index: 200;
    bottom: 0;
    left: 0;
    background-color: #2b3841;
    color: $color-default-white;
    width: 75px;
    height: 20px;
    text-align: center;
  }

  .crm-eol {
    bottom: 20px;
    background-color: #a00;
  }

  .crm-margin {
    font-weight: bold;
  }
}

.image-wrapper.certified::after {
  content: "";
  display: block;
  background-image: url("/static/landingpage/7.67.1/wwwroot/svg/komplett-certified.svg");
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  top: 10px;
  left: 0;
  width: 100px;
  height: 36px;
}

@import "_ProductListItemBox";
@import "_ProductListItemLine";
@import "_ProductListItemTouchDevice";
@import "_ProductListItemAd";
@import "_ProductListItemAdCompact";
@import "../CmsProductList/_ProductListItemWithFilters";
@import "_FlexProductListItem";
@import "_ProductListItemGrid";