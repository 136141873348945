@media (max-width: $screen-sm-max) {
  .flex-renewal-banner,
  .flex-renewal-placement-heading {
    padding-left: 10px;
  }
}

@media (min-width: $screen-md-min) {
  .float-for-flexbanner {
    padding-top: 60px;
  }
}

.flex-renewal {
  padding-left: 30px;
  padding-right: 30px;

  .product-list-item.flex-product {
    border: none;
  }

  &-subheading {
    font-weight: 300;
    font-size: 25px;
  }

  &-placement {
    margin-top: 30px;

    &-heading {
      font-size: 22px;
      font-weight: 500;
    }
  }
}

.flex-product {
  &-thumbnail {
    border: 1px solid rgb(229, 229, 229);
    border-radius: 10px;
  }

  &-content {
    padding: 10px 10px 20px;
  }

  &-description {
    display: flex;
    flex-direction: row;
  }

  &-price {
    flex: auto;
  }
}

.flex-renewal-banner {
  margin-bottom: 20px;
}

.flex-recommendation-timeout-message-wrapper {
  background-color: #e8f4fb;
  border-radius: 5px;

  min-width: 30%;
  max-width: 90%;
  
  padding: 1rem;
  margin: auto;
  
  .flex-recommendation-timeout-message-icon-wrapper {
    display: flex;
    flex-direction: row;
    
    .flex-recommendation-timeout-message-text{
      margin: auto;
      margin-left: 1rem;
    }
  }
}