.icon-filter-button__ubo {
    position: relative;
}


.toplist-buttons-group {
    .icon-filter-button__row {
        margin-bottom: 0;
        margin-top: 0;
    }
}

.ubo-box-icon {
    width: 100%;
}

.icon-filter-button__container {
    display: flex;
    padding-left: 0;
    margin: 0;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: $screen-sm) {
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow-x: auto;
        overscroll-behavior-inline: contain;
        scroll-snap-type: inline mandatory;
    }

    li {
        display: flex;
        margin-bottom: 8px;
        margin-right: 8px;

        .icon-filter-button__group-item {
            display: flex;
            align-items: center;
            width: 130px;
            max-height: 120px;
            flex-direction: column;
            flex: 0 0 auto;
            padding: 8px;
            cursor: pointer;
            user-select: none;
            border: 1px solid #E0E0E0;
            border-radius: 4px;
            text-decoration: none;

            @media (max-width: $screen-sm) {
                scroll-snap-align: start;

            }

            &--with-image {
                padding: 16px;
            }

            .icon-filter-button__image {
                height: 36px;
                width: 40px;

                path {
                    fill: #222;
                }
            }

            .icon-filter-button {
                &__name {
                    color: #222;
                    text-transform: none;
                    font-size: 14px;
                    text-align: center;
                    width: 100%;

                    &--with-image {
                        margin-top: 12px;
                    }
                }
            }

            &:hover {
                background-color: #f5f5f5;
            }

            &.icon-filter-button--active {
                background-color: #222;

                .icon-filter-button__name {
                    color: white;
                }

                .icon-filter-button__image {
                    path {
                        fill: white;
                    }
                }
            }
        }
    }
}