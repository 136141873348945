.product-energy-logo {
    text-align: right;
    min-height: 65px;

    a {
        display: block;
    }
}

.energyLogo .product-energy-logo {
    margin-bottom: 0;

    .technical-details {
        margin-top: 3px;
        margin-right: 0;
    }
}

.product-energy-logo-iframe {
    margin-top: 10px;
    width: 272px;
    height: 560px;
}

.energyLogo .product-energy-logo-img {
    // This height is calculated from the common font size shared with the price (EU regulations)
    font-size: 44.8px;
}

.product-list {
    &.box .product-list-item .product-energy-logo-img {
        font-size: 24px;
    }

    &.list .product-list-item .product-energy-logo-img {
        font-size: 28px;

        @media (max-width: $screen-sm-max) {
            font-size: 24px;
        }
    }
}

.product-list-item.product-ad .product-energy-logo-img {
    font-size: 28px;
}

.recommendations .recommendations-content .product-energy-logo-img {
    font-size: 24px;
}

.technical-ubo {
    .ubo-content {
        @media (min-width: $screen-sm-min) {
            min-width: 450px;
        }

        max-width: 450px;
        word-wrap: break-word;

        #ccs-product-fiche {
            height: 650px;
        }
    }
}

@media (max-width: $screen-sm-min) {
    .ubo.technical-ubo,
    .ubo.energy-ubo {
        .ubo-box {
            -webkit-overflow-scrolling: touch;
        }
    }

    .ubo.technical-ubo .ubo-box.scroll {
        padding-left: 0;
        padding-right: 0;

        .ubo-close-button {
            right: 0;
        }
    }

    .ubo-inline-text {
        padding: 0 40px;
    }
}

.ubo-content {
    .product-energy {
        display: flex;
        flex-direction: column;
    }
}
