
//Internal toolbar
$internal-toolbar-link-color: #34dddd !default;
$internal-toolbar-bg-color: $color-default-black !default;
$internal-toolbar-text-color: $color-default-white !default;
$internal-toolbar-font-size: 13px !default;
$internal-toolbar-height: 40px !default;

%text-truncate {
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

%internal-toolbar {
    background-color: $internal-toolbar-bg-color;
    color: $internal-toolbar-text-color;
    height: $internal-toolbar-height;
    font-size: $internal-toolbar-font-size;
    width: 100%;
    flex: 0 0 auto;

    h4 {
        font-weight: normal;
        text-transform: uppercase;
        display: inline-block;
        line-height: $internal-toolbar-height;
        font-size: $internal-toolbar-font-size;
        margin: 0;
        color: $internal-toolbar-text-color;
    }

    a, input {
        color: $internal-toolbar-link-color;
    }
}

%clean-list {
    &, > li {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}

%flexbox-column {
    display: flex;
    flex-direction: column;
}

%flexbox-row {
    display: flex;
    flex-direction: row;
}

%debug {
    outline: 1px solid chartreuse;
}
