.row {
    .instagram-images {
        margin-top: 20px;
        width: 100%;

        .title-wrapper {
            text-align: center;

            .icon {
                width: 38px;
                height: 38px;
                display: inline-block;
                margin-right: 5px;

                &.store-instagram-logo {
                    background: url("/mlf/skins/komplettno/social/instagram.svg");
                }
            }

            .title {
                color: #222;
                font-size: 18px;
                vertical-align: middle;
            }
        }

        .images-wrapper {
            .swiper-slide {
                cursor: default;

                .instagram-image-content {
                    padding: 10px;
                    margin: 0 5px;
                    height: 100%;
                    max-height: 180px;
                    max-width: 180px;

                    &--visible {
                        max-height: none;
                        max-width: none;
                    }

                    a {
                        display: block;
                        position: relative;
                        height: 100%;
                    }

                    .image {
                        max-width: 100%;
                        min-height: 100%;
                        object-fit: cover;

                        &__fallback {
                            background-size: cover;
                            background-position: center;
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}
