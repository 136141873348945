.thumbnail-list {
  display: grid;
  gap: 18px;
  margin-top: 15px;
  list-style-type: none;
  padding-inline-start: 0px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 151px);

  @media (max-width: $screen-sm-max) {
    gap: 30px;
  }

  .thumbnail-item {
    font-size: 16px;
    border: #e1e2e1 1px solid;
    transition: border 0.2s;
    transition-timing-function: ease-in;
    text-align: center;
    border-radius: 5px;
    background: white;

    @media (max-width: $screen-sm-max) {
      font-size: 14px;
    }

    a {
      text-decoration: none;
      height: 100%;
      display: flex;
      gap: 14px;
      flex-direction: column;
      justify-content: start;
      padding: 22px 0 4px;

      @media (min-width: $screen-sm-max) {
        gap: 18px;
      }
    }

    &:hover {
      border: #777 1px solid;
      .thumbnail-image {
        transform: scale(1.3);
      }
    }
    
    .thumbnail-image {
      aspect-ratio: 180 / 135;
      object-fit: cover;
      height: auto;
      transition: transform 0.5s;
      margin: 0 auto;
    }

    .thumbnail-text {
      font-weight: bold;
      color: #333;
      margin: 0 10px 0;
    }
  }
}
