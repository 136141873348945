.iframe-wrapper {
    width: 100%;
}

.iframe-wrapper iframe {
    width: 100%;
    overflow-y: auto;
    border: 0;
}

