.price-on-request {
    &-button {
        &__container {
            display: flex;
            justify-content: flex-end;
            margin-left: auto;
        }
    }

    &__modal {
        .ubo-center-position-wrapper {
            @media (max-width: $screen-xs-max) {
                position: fixed !important;
                top: 0;
                bottom: 0;
            }
        }

        .ubo-header-text {
            display: none;
        }

        .ubo-close-button {
            right: 0 !important;
            top: 0 !important;
        }

        .ubo-box {
            padding: 0;
            min-height: 200px;
            max-height: 760px;

            @media (min-width: $screen-sm-min) {
                min-width: 784px;
            }

            @media (max-width: $screen-xs-max) {
                max-width: 100% !important;
                width: 100%;
            }

            .ubo-content {
                max-width: 784px;
                width: 100%;

                .throbber {
                    padding-top: 180px;
                    width: 100%;
                }
            }
        }
    }

    &__content {
        display: flex;
        height: 100%;
        padding: 16px 32px;

        @media (max-width: $screen-xs-max) {
            flex-direction: column;
        }
    }

    &__info {
        flex: 1;

        @media (min-width: $screen-sm-min) {
            margin-right: 12px;
        }

        &--text {
            margin: 16px 0;
        }
    }

    &__title {
        font-size: 24px;
        line-height: 24px;
        margin-top: 0;
        font-weight: 600;
    }

    &__image {
        width: 300px;

        &--wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__details {
        flex: 1;

        @media (min-width: $screen-sm-min) {
            margin-left: 12px;
        }

        .price-on-request__form {
            display: flex;
            flex-direction: column;

            .input-set {
                margin-bottom: 16px;

                input[type="text"], input[type="email"] {
                    background-color: white;
                }
            }

            .textarea-set {
                margin-bottom: 16px;

                textarea {
                    background-color: white;
                }
            }
        }
    }

    &__submit {
        width: 50%;
        align-self: flex-end;
    }

    &-confirmation {
        &__modal {
            .ubo-center-position-wrapper {
                @media (max-width: $screen-xs-max) {
                    position: fixed !important;
                    top: 0;
                    bottom: 0;
                }
            }

            .ubo-header {
                display: none;
            }

            .ubo-box {
                width: 436px;

                @media (max-width: $screen-xs-max) {
                    max-width: 100% !important;
                    width: 100%;
                    height: 100%;
                    max-height: none !important;
                    border: 0;
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__image {
            width: 250px;
            margin: 16px 0;
        }

        &__message {
            margin: 16px 0;
            text-align: center;
        }

        &__button {
            width: 146px;
        }
    }
}
