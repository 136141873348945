.embed-responsive {
    height: 100%;
}

.product-list-item.product-ad.product-ad-compact {
    flex-direction: row;

    .image-container {
        padding: 0;
        width: 40%;
        align-self: center;

        .discount-bubble {
            font-size: 50px;
            margin-top: -25px;

            .discount-label {
                font-size: 16px;
            }
        }

        @media (min-width: $screen-md-min) {
            .discount-bubble {
                font-size: 50px;
                margin-top: -30px;

                .discount-label {
                    font-size: 16px;
                }
            }
        }
    }

    .content-block {
        width: 60%;
    }
}
