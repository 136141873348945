%svg__default {
  background-image: url("/static/landingpage/7.67.1/wwwroot/sprite-sheets/default.svg");
  background-repeat: no-repeat;
  background-size: 3.5em 3.5em;
  display: inline-block;
}

.fa-darkgrey-caret-down {
  @extend %svg__default;
  background-position: -0.25em -0.25em;
  width: 1em;
  height: 1em;
}
.fa-darkgrey-caret-up {
  @extend %svg__default;
  background-position: -1.5em -0.25em;
  width: 1em;
  height: 1em;
}
.fa-darkgrey-chevron-down {
  @extend %svg__default;
  background-position: -0.25em -1.5em;
  width: 1em;
  height: 1em;
}
.fa-darkgrey-download {
  @extend %svg__default;
  background-position: -1.5em -1.5em;
  width: 1em;
  height: 1em;
}
.icomoon-grid2-grey {
  @extend %svg__default;
  background-position: -2.75em -0.25em;
  width: 0.5em;
  height: 0.5em;
}
.icomoon-grid2-white {
  @extend %svg__default;
  background-position: -2.75em -1em;
  width: 0.5em;
  height: 0.5em;
}
.icomoon-list-grey {
  @extend %svg__default;
  background-position: -2.75em -1.75em;
  width: 0.5em;
  height: 0.5em;
}
.icomoon-list-white {
  @extend %svg__default;
  background-position: -0.25em -2.75em;
  width: 0.5em;
  height: 0.5em;
}
.fa-darkgrey-cart {
  @extend %svg__default;
  background-position: -1em -2.75em;
  width: 0.46875em;
  height: 0.4375em;
}
