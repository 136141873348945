﻿@use "sass:math";

$background-color-release-date: #466470 !default;

.box {
    .product-list-item {
        width: 100%;

        .product-release-date {
            background-color: $background-color-release-date;
            height: 20px;
            width: 100%;
            color: $color-default-white;
            margin: math.div($product-box-spacing, 2) 0;
            font-weight: normal;
            line-height: 20px;
            padding-left: 15px;

            &-day {
                font-weight: bold;
            }
        }

        @media (min-width: $screen-xs-min) {
            flex-direction: column;
            flex: 1 0 234px;
            width: auto;
            // Safari fix to ensure that the flex-basis is respected
            min-width: 0;

            .image-container {
                width: 100%;
                padding: $product-box-spacing*2 $product-box-spacing*2 $product-box-spacing;

                .image-wrapper {
                    padding: 0;
                }
            }

            .text-container {
                padding: 0 0 $product-box-spacing;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }

            .content-block {
                width: 100%;
                flex-grow: 1;
                padding: 0 $product-box-spacing*2 $product-box-spacing*2;
            }

            .box-bottom {
                align-items: flex-end;

                &.product-list-item--without-price-and-button {
                    .subscribe-product__redirect {
                        min-height: 32px;
                    }
                }
            }

            .stockstatus {
                position: static;
                margin-top: 5px;
                padding: 0;
                text-align: left;
                max-width: 100%;
                align-self: flex-start;
            }

            .product-seller, .delivery {
                position: static;
                margin-top: 5px;
                padding: 0;
                flex: 1 1 $screen-xs-max;
                text-align: left;
                max-width: 100%;
                align-self: flex-start;
            }

            .product-seller {
                align-self: flex-end;
            }

            .product-energy-logo {
                margin-bottom: 0;
            }

            .price-wrapper {
                min-height: 34px;
                justify-content: flex-end;
                position: static;
            }

            .buy-button, .subscribe-product__redirect, .price-on-request-button__container {
                position: absolute;
                right: $product-box-spacing;

                .quantity-picker {
                    display: none;
                }

                .btn-small {
                    height: 34px;
                    padding-left: 6px;
                    padding-right: 6px;
                    white-space: normal;
                }
            }
        }

        @media (min-width: $screen-sm-min) {
            max-width: 385px;

            .product-seller, .delivery {
                flex: 1 1 385px;
            }

            .product-seller.product-seller--auto-width {
                flex: 0 0 auto;
            }
        }

        &--without-buy {
            .price-wrapper {
                .product-price {
                    min-height: 32px;
                    align-items: flex-end;
                    display: flex;

                    &-now {
                        padding-bottom: 2px;
                    }
                }
            }
        }
    }
}
