.campaign-filter {
    &__wrapper {
        position: relative;
    }

    &__dropdown {
        position: absolute;
        background: white;
        color: #222;
        padding: 16px;
        z-index: 2;
        list-style: none;
        border: 1px solid #ccc;
        top: 39px;
        width: 220px;

        &-item {
            position: relative;
            padding-left: 22px;
            padding-top: 6px;
            padding-bottom: 6px;

            &:last-child {
                margin-bottom: 0;
            }

            input[type="checkbox"] + label::after {
                content: none;
            }

            input[type="checkbox"]:checked + label::after {
                content: "";
            }

            input[type="checkbox"]:focus + label::before {
                outline: rgb(59, 153, 252) auto 5px;
            }
        }

        &-item--name {
            font-weight: normal;
            user-select: none;
            margin-bottom: 0;
            font-size: 14px;

            @media(max-width: $screen-md-min) {
                font-size: 16px;
            }

            &::before {
                position: absolute;
                content: "";
                display: inline-block;
                height: 13px;
                width: 13px;
                border: 1px solid;
                left: 0px;
                top: 9px;
                border-radius: 2px;

                @media(max-width: $screen-md-min) {
                    height: 15px;
                    width: 15px;
                    top: 10px;
                }
            }

            &::after {
                position: absolute;
                content: "";
                display: inline-block;
                height: 4px;
                width: 9px;
                border-left: 2px solid;
                border-bottom: 2px solid;
                transform: rotate(-45deg);
                left: 2px;
                top: 12px;

                @media(max-width: $screen-md-min) {
                    top: 14px;
                    height: 5px;
                    width: 10px;
                }
            }
        }
    }

    &__search-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding: 8px 16px;

        &--wrapper {
            width: 100%;

            @media(max-width: $screen-md-min) {
                background: #fff;
            }
        }
    }

    &__search--btn-group {
        display: flex;
        align-items: center;
    }

    &__select-container {
        color: #222;
        position: relative;
        display: flex;
        flex: 0 0 auto;

        @media (max-width: $screen-md-min) {
            flex: 1 1 100%;
        }

        .select-sort {
            margin: 5px;
            width: 100%;
            height: 37px;
            border: 1px solid #DDD;
            padding-left: 0.8em;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            font-size: 16px;
            display: flex;
            align-items: center;
            padding-right: 2em;
            background: white;
            border-radius: 4px;

            &::-ms-expand {
                display: none;
            }

            @media (max-width: $screen-md-min) {
                margin: 0;
                margin-top: 8px;
                padding-right: 0;
                height: 40px;
                border-radius: 0;
            }
        }

        &:after {
            content: "";
            position: absolute;
            pointer-events: none;
            @extend .fa-darkgrey-caret-down;
            @extend .icon-md;
            @extend .icon;
            right: 15px;
            top: 11px;

            @media (max-width: $screen-md-min) {
                right: 15px;
                top: 15px;
            }
        }
    }

    &__filter-button {
        background: white;
        border: 1px solid #e1e2e1;
        height: 37px;
        font-size: 16px;
        line-height: 19px;
        color: #222;
        border-radius: 4px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        user-select: none;

        &.active {
            background-color: black;
            color: white;
        }

        &--icon {
            margin-left: 8px;
        }
    }

    &__input-group {
        display: flex;
        align-items: center;
        margin-right: 12px;
        user-select: none;

        &.mobile {
            justify-content: center;
            font-size: 16px;
            margin-top: 8px;
        }

        &--text {
            font-weight: normal;
        }

        .checkbox-input-group {
            margin-bottom: 0;
            display: flex;
            align-items: center;

            input[type="checkbox"] {
                margin: 0;
                margin-right: 4px;
            }
        }
    }

    &__price-dropdown {
        width: 250px;
        position: absolute;
        background: white;
        padding: 16px 16px 0 16px;
        z-index: 2;
        border: 1px solid #ccc;
        top: 39px;

        &.facets {
            .facet {
                .slider {
                    .values {
                        margin-top: 1em;
                    }
                }
            }
        }
    }

    &__price-mobile {
        .facet-title-button {
            text-align: center;
            font-size: 16px;
        }
    }

    &__mobile {
        padding: 16px 16px 8px;
        list-style: none;
        margin: 0;
    }

    &__container {
        .hidden-visible-SR {
            position: absolute;
            left: -10000px;
            top: auto;
            width: 1px;
            height: 1px;
            overflow: hidden;
        }
    }

    &__button {
        width: 100%;
        height: 42px;
        border-radius: 0;
        font-size: 16px;
        text-transform: none;

        &:hover:active {
            margin-top: 0;
        }
    }

    &__filter-button-mobile {
        width: 100%;
        padding: 7.5px;
        border: 1px solid #e8e8e8;
        background: #f8f8f8;
        text-align: left;
        color: #545454;
        font-size: 16px;

        .icon {
            float: right;
        }
    }

    &__facets {
        padding: 0 16px 16px;
        min-height: calc(100vh - 95px);
    }

    &__show-mobile {
        position: sticky;
        bottom: 0;
        margin-top: auto;
        z-index: 2000;

        &.row {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__show-all {
        border: 0;
        background: none;
        color: #017AB4;
        font-size: 16px;
        margin-bottom: 16px;
    }

    &__show-in-stock {
        position: relative;
        padding-left: 18px;

        .show-in-stock--label {
            @extend .campaign-filter__dropdown-item--name;

            &:before {
                top: 3px;
            }

            &:after {
                top: 6px;
            }
        }

        input[type="checkbox"] + label::after {
            content: none;
        }

        input[type="checkbox"]:checked + label::after {
            content: "";
        }

        input[type="checkbox"]:focus + label::before {
            outline: rgb(59, 153, 252) auto 5px;
        }
    }

    .applied-filter-wrapper {
        display: flex;
        margin: 8px 15px;
        overflow-x: auto;
        -ms-overflow-style: none;
        flex: 1 1 100%;

        @media (max-width: $screen-md-min) {
            margin: 8px 0;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .filter-tag {
            background-color: #f5f5f5;
            border: 1px solid #e1e2e1;
            color: #222;
            border-radius: 4px;
            padding: 4px 8px;
            display: flex;
            align-items: center;
            flex: none;
            margin-right: 8px;

            &:last-of-type {
                margin-right: 0;
            }

            &__field {
                margin-right: 4px;
                font-size: 16px;
                line-height: 24px;
            }

            &__name {
                font-size: 16px;
                line-height: 24px;
                margin-right: 8px;
            }

            &__remove {
                cursor: pointer;
                background: transparent;
                border: 0;
                display: flex;
            }
        }
    }

    &__hits {
        font-size: 14px;
        color: #222;
        margin-left: 12px;
    }

    &__see-more {
        padding: 0;
        list-style: none;

        &--link {
            font-size: 16px;
            padding-left: 14px;
        }
    }
}
