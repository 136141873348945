.quantity-limit-item-in-cart {
    .fa-info-circle-blue {
        position: absolute;
        margin-left: -25px;
    }

    &.btn-small {
        padding-left: 35px;

        .fa-info-circle-blue {
            margin-top: -2px;
        }
    }
}
