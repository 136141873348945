﻿$top-list-button-xs-padding: 0 22px !default;

.toplist-buttons-group {
  margin-top: 10px;
  margin-bottom: 10px;

  .group-dropdown {
    display: block;
    height: 40px;

    @media (min-width: $screen-sm) {
      display: none;
    }

    &:active {
      margin-top: 0;
    }
  }

  .ubo {
    width: 100%;
    text-align: left;
    top: 0;
    left: 0;
    z-index: 10;
    display: none;

    @media (min-width: $screen-sm) {
      display: block;
      position: relative;
      left: 0;
    }

    &.show-me {
      display: block;
    }

    .ubo-box {
      width: 100%;
      min-width: 0;
      padding: 0;

      @media (min-width: $screen-sm) {
        border: 0;
        position: relative;
        box-shadow: none;
      }

      .ubo-content {
        width: 100%;
        margin: 0;
        padding: 0;

        @media (min-width: $screen-sm) {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
        }

        li {
          list-style: none;

          @media (min-width: $screen-sm) {
            float: left;
            margin: 0 7px 7px 0;
            padding: 0;
          }

          a {
            color: $button-color;

            @media (max-width: $screen-xs-max) {
              border-bottom: none;
              background-color: $color-default-white;
              height: 40px;
              line-height: 40px;
              padding: $top-list-button-xs-padding;
              font-size: 13px;
              border-radius: 0;
              border-left: none;
              border-right: none;

              &:active {
                margin-top: 0;
              }

              &.secondary {
                border-top: $button-tertiary-white-border;
              }
            }

            @media (min-width: $screen-sm) {
              padding: $button-small-top-bottom-padding $button-small-left-right-padding;
            }
          }
        }

        .secondary {
          @media (min-width: $screen-sm) {
            color: $button-secondary-color;

            &:hover, &:focus {
              color: $button-secondary-hover-focus-color;
              background: $button-secondary-background-hover;
            }

            &:active {
              color: $button-secondary-hover-focus-color;
              background: $button-secondary-background-active;
            }
          }
        }
      }
    }
  }
}
