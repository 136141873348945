$promo-icon-border-radius: 3px !default;

.banner-carousel {
    width: 100%;
    overflow: hidden;

    @media (max-width: $screen-md - 1) {
        padding: 0 0 40px 0;
    }

    .swiper-wrapper {
        .swiper-slide {
            display: flex;
            position: relative;
            background: #333;

            picture {
                display: flex;
                flex: 1;
            }

            @media (max-width: $screen-md - 1) {
                box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
                width: 80%;
            }

            .swiper-lazy-preloader {
                position: absolute;
                background: #333;
                height: 100%;
                width: 100%;
            }
        }
    }

    &-image {
        width: 100%;
        height: 100%;
    }

    .pager {
        position: absolute;
        display: block;
        bottom: 0;
        height: 100%;
        width: 100%;
        text-align: center;
        font-size: 14px;
        pointer-events: none;

        button {
            background: transparent;
            border: none;
        }

        .touch-friendly-link {
            text-align: center;
            height: 40px;
            z-index: 4;
        }
    }

    img:-moz-loading {
        visibility: hidden;
    }
}
