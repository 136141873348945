@at-root {
    body[data-touch=true] {
        .product-list-item {
            &:hover, &:focus {
                box-shadow: none;
            }
        }

        .list {
            .product-list-item {
                @media (min-width: $screen-md-min) {
                    &:hover, &:hover + .product-list-item {
                        border-top-color: $border-color;
                    }
                }
            }
        }
    }
}
