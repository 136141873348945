$promo-icon-border-radius: 3px !default;
$skeleton-color-base: #e7e7e7;

.promo {
    width: 100%;
    overflow: hidden;
    --desktop-ratio: 1200 / 300;
    --mobile-ratio: 480 / 200;
    aspect-ratio: var(--desktop-ratio);

    @media (max-width: $screen-xs-min) {
        aspect-ratio: var(--mobile-ratio);
    }

    .swiper-wrapper {
        .swiper-slide {
            display: flex;
        }
    }

    &-image {
        width: 100%;
        height: 100%;

        &__skeleton {
            position: relative;
            background: $skeleton-color-base;

            &::after {
                display: block;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: translateX(-100%);
                background: linear-gradient(
                    90deg,
                    transparent,
                    rgba(255, 255, 255, 0.5),
                    transparent
                );
                animation: progress 1.5s ease-in-out infinite;
            }
        }
    }

    &-placeholder-image img {
        max-width: 100%;
        display: block;
        margin: 0 auto;

        &.size-xs {
            @media (min-width: $screen-sm-min) {
                display: none;
            }
        }

        &.size-lg {
            display: none;

            @media (min-width: $screen-sm-min) {
                display: block;
            }
        }
    }

    .pager {
        position: absolute;
        display: block;
        bottom: 15px;
        width: 100%;
        text-align: center;
        font-size: 14px;

        .touch-friendly-link {
            text-align: center;
            height: 40px;
            z-index: 4;
        }
    }

    button {
        border: none;
        background: transparent;
    }

    img:-moz-loading {
        visibility: hidden;
    }
}

@keyframes progress {
    100% {
        transform: translateX(100%);
    }
}
