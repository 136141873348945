@mixin gridArea($row, $column) {
  grid-area: #{$row} / #{$column} / #{$row} / #{$column};
}

@mixin stickerSides($start) {
  &-top-right {
    @include gridArea(1 + $start, 2);
  }

  &-middle-right {
    @include gridArea(2 + $start, 2);
  }
  &-middle-left {
    @include gridArea(2 + $start, 1);
  }

  &-bottom-right {
    @include gridArea(3 + $start, 2);
  }
  &-bottom-left {
    @include gridArea(3 + $start, 1);
  }
}

.list .product-box-container {
  @media (min-width: $screen-sm-min) {
    padding: 20px 5px 20px 10px;
    grid-template-columns: 65px 65px 1fr 120px 130px;
    grid-template-rows: auto 0.5fr auto auto auto auto auto;
    gap: 0 10px;
    grid-template-areas:
      ". . name price camparison"
      "image image description price calltoaction"
      "image image rating price calltoaction"
      "image image stockstatus price energylogo"
      ". . variants . ."
      ". . deliverytime . ."
      ". . productnumbers . .";

    .camparison {
      grid-area: camparison;
    }

    .sticker {
      @include gridArea(1 + 1, 1);

      &.sticker {
        @include stickerSides(1);
      }
    }

    .discount-percent {
      @include gridArea(2 + 1, 1);
    }

    .discount-currency {
      @include gridArea(3 + 1, 1);
    }

    .discount-club {
      grid-area: 5 / 1 / 7 / 3;
      justify-self: start;
    }

    .energylogo {
      padding-top: 8px;
      .product-energy-logo {
        flex-direction: row-reverse;
      }
    }

    .call-to-action {
      .with-quantity-picker {
        margin-right: 45px;
        margin-top: -30px;

        .buy-button {
          display: flex;
          flex-direction: column;
          gap: 6px;
        }

        .quantity-picker {
          display: block;
        }
      }
    }
  }

  .call-to-action {
    align-self: center;
  }

  @media (min-width: $screen-md-min) {
    .productnumbers div {
      display: block;
    }
  }

  .description,
  .image {
    margin-bottom: 5px;
  }

  .stockstatus,
  .productnumbers .product-data,
  .energylogo .product-energy-logo {
    margin-top: 5px;
  }
}

.box .product-box-container {
  @media (min-width: $screen-sm-min) {
    max-width: 412px;
  }

  @media (min-width: $screen-xs-min) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 30px repeat(3, minmax(46px, auto)) 25px 33px 16px 37px 22px repeat(3, auto) 1fr;
    gap: 5px 5px;
    grid-template-areas:
      ". ."
      "image image"
      "image image"
      "image image"
      ". ."
      "name name"
      "rating rating"
      "description description"
      "stockstatus stockstatus"
      "variants variants"
      "deliverytime deliverytime"
      "productnumbers productnumbers"
      "price calltoaction";

    flex: 1 0 234px;
    width: auto;
    min-width: 0;
    border: 2px solid #e6e6e6;
    padding: 10px;

    .image {
      width: 100%;

      .product-image {
        width: 100%;
      }
    }

    .sticker {
      @include gridArea(1 + 1, 1);

      &.sticker {
        @include stickerSides(1);
      }
    }

    .discount-percent {
      @include gridArea(2 + 1, 1);
    }

    .discount-currency {
      @include gridArea(3 + 1, 1);
    }

    .discount-club {
      @include gridArea(2 + 1, 2);
    }

    .description,
    .name {
      overflow: hidden;
    }

    .rating {
      .rating-stars {
        font-size: 14px;
      }
    }

    .energylogo {
      grid-area: 1 / 1 / 1 / 1;
      z-index: 1;

      .product-energy-logo {
        flex-direction: column;
        gap: 0;
      }
    }
  }

  .call-to-action {
    align-self: end;
  }

  .price {
    align-self: end;
  }
}

.product-box-container {
  position: relative;
  font-size: 13px;
  border-bottom: 1px solid $border-color;
  align-content: start;
  padding: 20px;
  flex: 1 1 100%;
  display: grid;
  grid-template-columns: 65px 65px 1fr 1fr;
  grid-template-rows: repeat(9, auto);
  gap: 0 10px;
  background: #fff;
  border-radius: 5px;
  grid-template-areas:
    "image image name name"
    "image image description description"
    "image image rating rating"
    ". . stockstatus stockstatus"
    ". . energylogo energylogo"
    ". . variants variants"
    ". . productnumbers productnumbers"
    ". . deliverytime deliverytime"
    "price price calltoaction calltoaction";

  @media (max-width: $screen-sm-min) {
    .camparison {
      .comparison-checkbox {
        display: none;
      }
    }
  }

  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 3px #9cd7f8;
    border-color: #9cd7f8;
  }

  &:hover .product-image,
  &:focus .product-image {
    transform: scale(1.2);
  }

  .product-link {
    color: #222;
    text-decoration: none;
  }

  .image {
    grid-area: image;
    align-self: center;
    justify-self: center;
    position: relative;
    max-width: 100%;

    .image-wrapper {
      @supports (overflow-x: clip) {
        & {
          overflow-x: clip;
        }
      }
      @supports not (overflow-x: clip) {
        & {
          overflow: hidden;
        }
      }
      transform: translateZ(0);
    }

    .product-image {
      display: block;
      max-width: 100%;
      height: auto;
      transition: transform 1s;
    }

    .crm-only {
      background-color: #2b3841;
      color: #fff;
      width: 75px;
      height: 20px;
      text-align: center;
    }
  }

  .camparison {
    grid-area: 1 / 2 / 1 / 2;
    justify-self: end;

    label span {
      display: none;
    }
  }

  .sticker,
  .discount-percent,
  .discount-currency,
  .discount-club {
    z-index: 1;
    pointer-events: none;
  }

  .sticker {
    grid-area: 1 / 1 / 1 / 1;

    &.sticker {
      @include stickerSides(0);
    }
  }

  .discount-percent {
    grid-area: 2 / 1 / 2 / 1;
    align-self: center;

    .discount-bubble {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      max-height: 0.5em;
      transform: scaleY(2);

      .discount-text {
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        transform: scaleY(0.5);

        @media (max-width: $screen-sm-max) {
          font-size: 13px;
        }
      }
    }
  }

  .discount-currency {
    grid-area: 3 / 1 / 3 / 1;
    white-space: nowrap;

    .discount-bubble--currency {
      display: inline-block;
      position: unset;
      z-index: unset;
      bottom: unset;
      left: unset;
    }
  }

  .discount-club {
    grid-area: 2 / 2 / 2 / 2;
    align-self: center;
    justify-self: end;
    transform: scaleY(2);

    .club-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      max-height: 0.5em;
      transform: scaleY(0.5);
    }
  }

  .call-to-action {
    grid-area: calltoaction;
    justify-self: end;

    .btn-small {
      border-bottom: 0;
      min-width: 60px;
      height: 29px;
      border-radius: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      text-transform: none;

      .icon {
        margin-right: 5px;
      }

      &::before {
        box-shadow: none;
      }
    }

    .with-quantity-picker {
      .quantity-picker {
        display: none;
      }
    }
  }

  .price {
    grid-area: price;

    .product-price-before {
      color: #666666;
    }

    .product-price,
    .product-price-subscription {
      white-space: nowrap;
    }

    .product-price-now,
    .product-price-now-label {
      font-size: 20px;
      font-weight: bold;
    }

    .product-price-subscription {
      .product-price-subscription-flex {
        font-style: italic;
        margin-right: 2px;
      }
    }

    .crm-margin {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .name {
    grid-area: name;

    h2 {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
    }
  }

  .description {
    grid-area: description;

    p {
      margin: 0;
    }
  }

  .variants {
    grid-area: variants;
  }
  .rating {
    grid-area: rating;
  }

  .stockstatus {
    grid-area: stockstatus;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .energylogo {
    grid-area: energylogo;

    .product-energy-logo {
      display: flex;
      gap: 5px;
      align-items: baseline;
      min-height: 0;

      .icon {
        font-size: 28px;
      }
    }
  }

  .productnumbers {
    grid-area: productnumbers;

    div {
      display: none;
    }
  }

  .deliverytime {
    grid-area: deliverytime;

    .delivery {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 145px;
    }
  }
}
