$recommendations-font-size: 14px !default;
$recommendations-swiper-slide-button-position: 8px !default;
$recommendations-swiper-slide-padding: 8px !default;
$skeleton-color-base: #e7e7e7;
$discount-bubble-currency-color: #d93717;

.recommendations {
    overflow: hidden;

    .swiper-container {
        margin-left: $recommendations-swiper-slide-padding;
        margin-right: $recommendations-swiper-slide-padding;
    }

    .swiper-slide-right-button {
        right: $recommendations-swiper-slide-button-position;
    }

    .swiper-slide-left-button {
        left: $recommendations-swiper-slide-button-position;
    }

    .swiper-slide-button .icon-20 {
        display: block;
    }

    .swiper-previous-body-next-container {
        @media (max-width: $screen-sm-min) {
            position: relative;

            .previous,
            .next {
                position: absolute;
                z-index: 10;
                top: 50%;
                transform: translateY(-50%);
            }

            .previous {
                left: 0;
            }

            .next {
                right: 0;
            }
        }
    }

    .swiper-wrapper {
        align-items: stretch;

        .swiper-slide {
            display: flex;

            &:hover {
                box-shadow: inset 0 0 0 3px $border-color;
            }

            &.slide-padder {
                cursor: default;

                &:hover {
                    box-shadow: none;
                }
            }
        }
    }

    &-content-container {
        position: relative;
        height: 397px;

        .recommendations-skeleton__wrapper {
            height: 100%;
            width: 100%;
            position: absolute;
        }
    }

    &-loader {
        height: 305px;
        width: 100%;
        transition: all 0.2s linear;

        &--hide {
            visibility: hidden;
            height: 0;
        }
    }

    &-content {
        min-width: 60px;
        padding: 0 10px;
        text-align: left;
        margin: 0 5px;

        .image-container {
            display: block;
            padding: 3px 0 5px;
            position: relative; // Needed for proper positioning of discount-bubble
            .image-wrapper {
                width: 100%;
            }

            .image {
                max-width: 100%;
                height: auto;
            }

            .discount-bubble {
                position: absolute;
                top: 50%;
                margin-top: -25px;
                left: $product-box-spacing / 2;
                z-index: 130;
                font-size: 45px;
                border-radius: 50%;
                background-color: $discount-bubble-currency-color;
                width: 1em;
                height: 1em;

                @media (max-width: $screen-sm-max) {
                    font-size: 32px;
                }

                .discount-label {
                    font-size: 14px;
                    color: #ffffff;

                    @media (max-width: $screen-sm-max) {
                        font-size: 11px;
                    }
                }
            }
        }

        .text-container {
            color: $text-color-default;
            text-decoration: none;

            .text-content {
                padding-bottom: 5px;
            }
        }

        .box-bottom {
            @extend %flexbox-row;
            margin-top: 3px;
            align-items: center;

            .rr-buy-area {
                margin-left: auto;
            }
        }

        .energy-logo {
            width: 100%;
            margin-top: 8px;
        }

        .product-energy-logo {
            text-align: left;
            width: 100%;
            font-size: 12px;
            margin-bottom: 0;
            min-height: 60px;

            .energy-logo-img-wrapper {
                margin-top: 5px;
            }
        }
    }

    &-rating-stars {
        visibility: visible;

        &.do-not-show {
            visibility: hidden;
        }
    }

    &-title {
        font-size: $font-size-default;
        font-weight: bold;
        text-align: center;
        margin-top: 0;
        margin-bottom: 10px;

        &:before {
            content: "";
            display: inline-block;
            height: 100%;
            width: 100%;
            position: relative;
            top: 1.1em;
            z-index: 1;
        }

        &.with-border {
            &:before {
                border-top: solid 1px #e6e6e6;
            }
        }

        &.new-carousel-layout-title {
            background-color: #f5f5f5;
            margin-top: 12px;
            display: flex;
            padding-top: 7px;
            padding-bottom: 7px;
            position: relative;

            .recommendations-title-content {
                margin: auto;
                background: none;
                font-size: 1.2em;
                height: 1.4em;
                color: #303030;
            }

            &:before {
                display: none;
            }

            &:after {
                content: "";
                position: absolute;
                bottom: -10px;
                left: 50%;
                margin-left: -10px;
                border-top: solid 10px #f5f5f5;
                border-left: solid 10px transparent;
                border-right: solid 10px transparent;
            }
        }

        &-content {
            padding: 0 20px;
            background: $color-default-white;
            position: relative;
            display: inline-block;
            z-index: 2;
        }
    }

    &-webtxt1,
    &-webtxt2 {
        line-height: 1.2em;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-webtxt1 {
        font-size: $recommendations-font-size;
        font-weight: bold;
        text-align: left;
        margin: 5px 0;
        max-height: 2.45em;
        min-height: 2.45em;
    }

    &-webtxt2 {
        font-size: $font-size-default;
        margin: 0;
        max-height: 1.25em;
        min-height: 1.25em;
        white-space: nowrap;
    }

    &-before-price {
        font-size: $font-size-default;
        margin-top: 2px;

        &-label {
            font-size: $font-size-default;
        }
    }

    &-now-price {
        font-size: 16px;
        font-weight: bold;

        &-label {
            font-size: $recommendations-font-size;
            font-weight: bold;
        }
    }

    .new-carousel-item {
        .recommendations-now-price {
            font-size: 26px;
            margin-top: 0.1em;
        }

        .recommendations-before-price {
            font-size: 12px;
            font-weight: 600;
            line-height: 0.2em;
            margin-top: 4px;

            &.do-not-show {
                visibility: hidden;
            }
        }
    }

    &-net-price {
        font-size: $font-size-default;
    }

    .btn-small {
        padding: 4px 8px;
        font-size: 11px;

        &.btn-icon {
            &:before,
            .icon {
                display: none;
            }
        }
    }

    &-extended {
        .flexbox-list {
            padding: 0 20px 30px;

            @supports (grid-gap: 0) {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(165px, auto));
            }
        }

        .recommendations-content {
            flex: 1 1 145px;
            margin: 0 !important;
            padding: 15px 15px !important;

            &:hover {
                box-shadow: inset 0 0 0 3px $border-color;
            }
        }
    }

    &-bundle {
        .bundle-container {
            padding: 0 20px 20px; 
            display: grid; 
            grid-template-columns: 1fr 31px 1fr 1fr; 

            @media (max-width: $screen-sm-min) {
                grid-template-columns: 1fr 31px 1fr; 
            }
        }

        .bundle-sign {
            font-size: 55px; 
            color: #F8B912;
            align-self: center;
        }

        .bundle-buybox {
            align-self: end;
            @media (max-width: $screen-sm-min) {
                grid-column: 1 / span 3;
                justify-self: center;
            }

            .btn-large {
                text-transform: none;
                min-width: 100%;
            }

            div {
                font-size: 18px; 
                font-weight: 600; 
                padding: 16px 0;
            }
        }

        .recommendations-content {
            padding: 0 4px !important;

            &:hover {
                box-shadow: inset 0 0 0 3px $border-color;
            }
        }
    }

    &-skeleton {
        &__wrapper {
            display: flex;
            flex: 1 1 100%;
            height: 310px;

            @media (max-width: $screen-sm-min) {
                height: 230px;
            }
        }

        &__item {
            margin: 3px 1em 1em;
            height: 100%;
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &--head {
            height: 150px;
            width: 80%;
            margin-bottom: 1em;
            background-color: $skeleton-color-base;

            @media (max-width: $screen-sm-min) {
                height: 71px;
                margin-bottom: 0.5em;
            }
        }

        &--line-long {
            width: 80%;
            height: 1.2em;
            margin-bottom: 1em;
            background-color: $skeleton-color-base;

            @media (max-width: $screen-sm-min) {
                height: 1em;
                margin-bottom: 0.5em;
            }
        }

        &--line-short {
            width: 30%;
            height: 1.4em;
            background-color: $skeleton-color-base;
            margin-top: 1em;
            margin-left: -50%;
        }

        &__hide-on-mobile {
            @media (max-width: $screen-sm-min) {
                display: none;
            }
        }
    }
}
