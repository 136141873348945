.newsletter {
  width: 100%;
  .hbspt-form {
    width: 100%;
  }

  .newsletter-subscribe-form {
    padding: 20px 30px;

    h4,
    p {
      word-break: break-word;
      word-wrap: break-word;
      white-space: normal;
      text-align: center;
    }

    h4 {
      font-size: 16px;
      font-weight: bold;
    }

    p {
      font-size: 14px;
    }

    label {
      font-size: 12px;
    }

    button {
      white-space: normal;
      word-break: break-word;
      word-wrap: break-word;
    }

    .newsletter-input {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      flex-wrap: wrap;

      .input-set {
        margin-bottom: 0;
      }

      .newsletter-email {
        max-width: 300px;
        width: 100%;
        flex: 0 0 auto;
      }

      input[type="email"] {
        float: none;
      }

      .newsletter-button {
        padding-top: 15px;
        flex: 0 0 auto;
      }
    }
  }

  .newsletter-success {
    padding: 20px 30px;
    text-align: center;

    .newsletter-success-icon {
      font-size: 60px;
    }
  }
}

.col-xs-12 {
  .newsletter-subscribe-form {
    padding: 20px 40px;

    @media (min-width: $screen-sm-min) {
      padding: 20px 13%;
    }

    @media (min-width: $screen-md-min) {
      padding: 20px 25%;
    }

    .newsletter-input {
      justify-content: center;
      align-items: flex-start;
      flex-direction: row;
    }

    .newsletter-button {
      padding-left: 15px;
    }
  }
}

.col-xs-12.col-sm-6,
.col-xs-6 {
  .newsletter-subscribe-form {
    padding: 20px 30px;
  }
}
