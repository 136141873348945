.quantity-picker {
    display: inline-block;
    line-height: 16px;
    min-width: 80px;

    label {
        margin-bottom: 0;
    }

    .adjust-button {
        display: inline-block;
        width: 27px;
        height: 25px;
        line-height: 25px;
        background: $button-background;
        text-shadow: none;
        text-align: center;
        vertical-align: middle;
        font-size: 13px;
        font-weight: normal;
        border: 1px solid #e6e6e6;
        margin: 0;
        padding: 0;
        color: $text-color-default;

        &:hover, &:focus {
            text-decoration: none;
        }

        &:hover:not(.disabled) {
            background: $button-background-hover;
        }

        &:active:not(.disabled) {
            background: $button-background-active;
        }

        &.disabled {
            color: $btn-link-disabled-color;
            cursor: default;
        }

        &.dec {
            border-radius: 3px 0 0 3px;
        }

        &.inc {
            border-radius: 0 3px 3px 0;
        }
    }

    .adjust-input {
        width: 28px;
        height: 25px;
        padding: 0;
        text-align: center;
        vertical-align: middle;
        margin: 0;
        box-shadow: none;
        border: 1px solid #e6e6e6;
        border-left: 0;
        border-right: 0;
        font-size: 16px;
        font-weight: normal;
        @extend %override-ios-useragent-stylesheet;
    }
}

%override-ios-useragent-stylesheet {
    -webkit-appearance: none;
    border-radius: 0;
}

.non-editable-quantity {
    font-size: 16px;
}