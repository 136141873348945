$border: 1px solid #e4e4e4;
$color-link-default: #0074bd;

.faq-section {
    width: 100%;
    border-bottom: $border;
}

.question-block {
    > h2, > h3 {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-weight: normal;
        justify-content: space-between;
        margin: 0;
        padding: 0 16px;

        &:hover {
            background-color: #f2f2f2;
        }
    }

    > h2 {
        border-top: $border;
        font-size: 16px;
        line-height: 40px;
    }

    > h3 {
        font-size: 14px;
        color: $color-link-default;
        line-height: 28px;

        .fa-icon {
            color: $color-default-black;

            &::before {
                font-size: 24px;
            }
        }
    }

    > .content {
        opacity: 0;
        height: 0;
        position: absolute;
        z-index: -1;
        visibility: hidden;

        .content {
            padding: 0;
        }

        p {
            margin: 10px 0;
        }
    }

    .fa-icon {
        transition: transform .2s ease-in-out;

        &::before {
            font-size: 26px;
        }
    }


    &.open {
        > h2, > h3 {
            font-weight: bold;
        }

        > h2 > i, > h3 > i {
            transform: rotate(-180deg);
        }

        > .content {
            position: static;
            height: auto;
            opacity: 1;
            padding: 0 40px;
            visibility: visible;
        }

        &.faq-category {
            > .content {
                padding: 0 0 0 24px;

                .content {
                    padding: 0 16px;
                }
            }
        }
    }
}
