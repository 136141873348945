.campaign-content {
    h1, h2, h3, h4, h5, h6 {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
    }

    h1 {
        font-size: 24px;
        margin-top: 10px;
        line-height: 1em;

        @media screen and (min-width: $screen-sm-min) {
            margin-top: 0;
        }

        @media screen and (min-width: $screen-md-min) {
            font-size: 36px;
            padding-left: 0;
        }
    }

    h2 {
        margin-top: 0;
    }

    h3 {
        margin-top: 0;
    }

    .ingress {
        font-size: 16px;
        font-weight: 400;
    }

    p + h2, p + h3, p + h4 {
        margin-top: 1em;
    }
}
