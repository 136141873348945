$countdown-title-background-color: $main-color !default;
$countdown-title-text-color: $navigation-header-text-color-active !default;
$countdown-display-background-color: $color-default-black !default;
$countdown-display-text-color: $color-default-white !default;
$countdown-numbers-glow-color: $color-default-white !default;

@font-face {
    font-family: "Liquid Crystal";
    src: url("../fonts/liquidcrystal-normal-webfont.eot");
    src: url("../fonts/liquidcrystal-normal-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/liquidcrystal-normal-webfont.woff2") format("woff2"), url("../fonts/liquidcrystal-normal-webfont.woff") format("woff"), url("../fonts/liquidcrystal-normal-webfont.ttf") format("truetype"), url("../fonts/liquidcrystal-normal-webfont.svg#liquid_crystalregular") format("svg");
    font-weight: normal;
    font-style: normal;
}

.ctdnt-Outer {
    width: 100%;
}

.ctdnt-Outer a {
    text-decoration: none;
}

.ctdnt-Display {
    background-color: $countdown-display-background-color;
    color: $countdown-display-text-color;
    flex-direction: row;
    display: flex;
    flex-wrap: nowrap;
    padding: 1em;
    text-align: center;
    justify-content: center;
    align-items: stretch;
}

.ctdnt-item {
    flex-direction: column;
    justify-content: center;
}

.ctdnt-numbers {
    font-size: 36px;
    font-family: "Liquid Crystal", arial, serif;
    font-weight: normal;
    vertical-align: text-top;
    padding: 0 5px;
    text-shadow: 0 0 3px $countdown-numbers-glow-color;
}

.ctdnt-separator {
}

.ctdnt-Labels {
    text-transform: uppercase;
    font-size: 10px;
    font-family: arial, serif;
    font-weight: normal;
}
