@use "sass:math";

.product-list-item.product-ad {
    border: 2px solid $color-default-light-grey;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;

    .product-ad--without-buy {
        .price-wrapper {
            .product-price {
                min-height: 27px;
                align-items: flex-end;
                display: flex;

                &-now {
                    padding-bottom: 2px;
                }
            }
        }
    }

    &:hover {
        border-color: darken($color-default-light-grey, 5%);
        box-shadow: none;
    }

    .image-container {
        padding: $product-box-spacing*2 $product-box-spacing 0;
        width: 100%;

        .image-wrapper {
            padding: 0;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                height: 100%;
            }
        }

        .discount-bubble {
            left: $product-box-spacing*2;
            margin-top: -35px;
            font-size: 50px;

            .discount-label {
                font-size: 16px;
            }
        }
    }

    .content-block {
        padding: $product-box-spacing $product-box-spacing*2 $product-box-spacing*2;
        width: 100%;
        flex-grow: 1;
    }

    .text-container {
        font-size: 14px;
        padding: 0;

        h2 {
            font-size: 16px;
            margin-bottom: 0.5em;
        }

        .description {
            padding-bottom: 5px;
        }
    }

    .box-bottom {
        align-items: flex-end;
        flex-direction: column;
        width: 100%;
    }

    .stockstatus {
        position: static;
        padding: 0;
        margin-top: 5px;
        width: 100%;
        flex: 1 1 auto;
        text-align: left;
    }

    .price-wrapper {
        margin-right: math.div($product-box-spacing, 2);
    }

    .buy-button, .subscribe-product__redirect, .price-on-request-button__container {
        margin: 0 0 0 auto;
    }
  
  .buy-button {
    .subscription-buy-button {
        background: #1f1949;
        color: #FFFFFF;
        padding-left: 8px;
        padding-right: 8px;
        border-bottom-color: #9b99ae;
        transition: background .2s ease-in-out;
        
        &:hover {
            background: #312B5F;
        }
    }
  }

    .btn-small {
        margin-top: $product-box-spacing;
        padding-left: 6px;
        padding-right: 6px;
    }

    @media (min-width: $screen-sm-min) {
        .box-bottom {
            flex-direction: row;
        }
    }

    @media (min-width: $screen-md-min) {
        .text-container {
            font-size: 13px;

            h2 {
                font-size: 14px;
            }
        }

        .discount-bubble {
            font-size: 50px;
            margin-top: -35px;

            .discount-label {
                font-size: 16px;
            }
        }
    }
}

.col-xs-12:not(.col-sm-6) {
    .product-list-item.product-ad {
        .product-link {
            flex-direction: column;
        }

        .review {
            .rating-stars {
                font-size: 24px;
                vertical-align: bottom;
            }

            .review-count {
                font-size: 15px;
            }
        }

        @media (min-width: $screen-sm-min) {
            flex-direction: row;

            .image-container {
                width: 40%;
                padding: $product-box-spacing*2 0 $product-box-spacing*3 $product-box-spacing*2;
            }

            .content-block {
                padding: $product-box-spacing*2 $product-box-spacing*2 $product-box-spacing*3 0;
                width: 60%;
            }

            .text-container {
                padding-bottom: $product-box-spacing;
            }

            .text-content {
                display: flex;
                flex-direction: column;
                font-size: 14px;

                h2 {
                    font-size: 20px;
                }
            }

            .price-wrapper {
                padding-top: math.div($product-box-spacing, 2);
            }

            .review {
                margin-bottom: $product-box-spacing;

                .rating-stars {
                    margin: 0;
                }
            }

            .stockstatus {
                padding-left: 0;
                margin-left: -2px;

                &-delivery {
                    margin-left: 0;
                }
            }
        }

        @media (min-width: $screen-md-min) {
            .text-content {
                font-size: 16px;

                h2 {
                    font-size: 24px;
                }
            }
        }
    }
}

.col-xs-12.col-sm-6 {
    .product-list-item.product-ad {
        .review {
            .rating-stars {
                font-size: 20px;
            }
        }

        @media (min-width: $screen-sm-min) {
            .text-container {
                font-size: 14px;

                h2 {
                    font-size: 18px;
                }
            }

            .review {
                .rating-stars {
                    font-size: 24px;
                }
            }
        }
    }
}

.col-xs-6.col-sm-4, .col-xs-6.col-md-3 {
    .product-list-item.product-ad {
        .product-link {
            flex-direction: column;
        }

        .discount-bubble {
            font-size: 50px;
            margin-top: -25px;

            .discount-label {
                font-size: 16px;
            }
        }

        .box-bottom {
            flex-direction: column;
            align-items: flex-start;

            .price-wrapper {
                position: static;
                margin-top: $product-box-spacing;
            }

            .buy-button, .subscribe-product__redirect, .price-on-request-button__container {
                margin: 0;
                align-self: flex-start;

                .btn-small {
                    margin-top: $product-box-spacing;
                }
            }
        }
    }
}

.col-xs-12.col-sm-6, .col-xs-6.col-md-3 {
    .product-list-item.product-ad {
        @media (min-width: $screen-sm-min) {
            .discount-bubble {
                font-size: 50px;
                margin-top: -35px;

                .discount-label {
                    font-size: 16px;
                }
            }
        }
    }
}

.col-xs-6.col-sm-4 {
    .product-list-item.product-ad {
        @media (min-width: $screen-lg-min) {
            .discount-bubble {
                font-size: 50px;
                margin-top: -35px;

                .discount-label {
                    font-size: 16px;
                }
            }
        }
    }
}

.col-xs-6.col-md-3 {
    .product-list-item.product-ad {
    }

    .banner-image + .product-list-item.product-ad, .product-list-item.product-ad + .product-list-item.product-ad {
        margin-top: math.div($small-gutter, 1.5);

        @media (min-width: $screen-md-min) {
            margin-top: $small-gutter;
        }
    }
}
