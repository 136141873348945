.rte-content {
    width: 100%;

    div div {
        padding: 30px;
    }

    img {
        max-width: 100%;
    }

    table {
        @media (max-width: $screen-md-min) {
            width: 100%;
        }
    }

    @media (max-width: $screen-md-min) {
        padding: 0 10px;
    }
}
