.rating-stars {
    display: inline-block;
    font-size: 18px;
    vertical-align: text-top;
}

.review-count {
    font-size: 13px;
    padding-left: 5px;
    vertical-align: middle;
}