.banner-image {
    display: block;
    width: 100%;

    img {
        max-width: 100%;
        width: 100%;
        display: block;
        margin: 0 auto;
        height: auto;

        &.size-xs {
            @media (min-width: $screen-sm-min) {
                display: none;
            }
        }

        &.size-lg {
            display: none;

            @media (min-width: $screen-sm-min) {
                display: block;
            }
        }
    }
}
