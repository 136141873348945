.expandable-block {
    width: 100%;
    background-color: #f6f4f3;
    margin: 2em 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.35);
    align-self: flex-start;

    @media (min-width: $screen-md-min) {
        margin: 1em 0;
    }

    h3 {
        padding: 10px 30px 10px 10px;
        margin: 0;
        font-weight: bold;
        font-size: 16px;
        position: relative;
        cursor: pointer;
        line-height: 20px;
        background-color: #f6f4f3;

        i {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            transition: transform .2s ease-in-out;
            font-size: 24px;
        }
    }

    .content {
        padding: 0 10px;
        opacity: 0;
        height: 0;
        display: none;

        img {
            width: 100%;
        }

        p, ol, ul, h2, h3, h4 {
            margin-top: 0;
        }

        h2 {
            padding-left: 0;
        }
    }

    &.open {
        h3 {
            i {
                transform: rotate(-180deg) translateY(50%);
            }
        }

        .content {
            transition: opacity .1s ease-in-out;
            height: auto;
            opacity: 1;
            padding: 10px;
            display: block;
        }
    }
}
