/*Global variables for all stores
  These variablels are loaded before the store specific variables
*/

@import "../../../../node_modules/@komplettnpm/lipstick/src/Styles/_lipstick-variables";
@import "../bootstrap/_variables";
$input-font-size-large: 16px;
$vertical-menu-header-font-weight: bold;
$button-font-size-larger: 12px !default;
$product-box-spacing: 10px !default;
$grid-space: 15px !default;
$headings-line-height: 1.2;
$checkbox-checked-icon: ".pe-checkbox-checked";
$checkbox-checked-disabled-icon: ".pe-checkbox-checked-disabled";
$checkbox-checked-focus-icon: ".pe-checkbox-checked-focus";
$checkbox-unchecked-icon: ".pe-checkbox-unchecked, .icon-sm";
$checkbox-unchecked-disabled-icon: ".pe-checkbox-unchecked-disabled, .icon-sm";
$checkbox-unchecked-focus-icon: ".pe-checkbox-unchecked-focus";
$radiobtn-checked-icon: ".pe-radiobtn-checked";
$radiobtn-checked-disabled-icon: "pe-radiobtn-checked-disabled";
$radiobtn-checked-focus-icon: ".pe-radiobtn-checked-focus";
$radiobtn-unchecked-icon: ".pe-radiobtn-unchecked, .icon-sm";
$radiobtn-unchecked-disabled-icon: ".pe-radiobtn-unchecked-disabled, .icon-sm";
$radiobtn-unchecked-focus-icon: ".pe-radiobtn-unchecked-focus";
$icon-input-wrapper: ".fa-colored-exclamation-circle-red, .icon-sm";
$container-background: $color-default-white !default;
$price-bomb-font-color: #1f1949 !default;
$default-box-shadow: 5px 6px 9px -4px #000 !default;
$default-border-width: 1px !default;
$double-border-width: $default-border-width * 2 !default;
$default-border: $default-border-width solid $border-color !default;
$double-border: $double-border-width solid $border-alt-color !default;
$menu-hover-bg-color: #f1f1f1 !default;
$body-font-color: #444 !default;
$hover-color: #a4c6fd !default;
$body-font-size: 1.1em !default;
$autocomplete-font-size: 1em !default;
$overlay-color: #000 !default;
$text-color-default-medium: #545454;
$text-color-default-light: #747474;
$body-padding-bottom: 12px;
$insurance-background-color: #477154;
$border-color-dark-grey: #999;
$font-family-base: Arial, Helvetica, sans-serif;

//Seletion
$selection-color: $text-color-default;

//Button
$button-hover-background: #fefbf3;