$discount-bubble-currency-color: #d93717;
$club-price-currency-color: #A5E2EB;

.discount-bubble {
  font-size: 32px;
  margin-top: -16px;
  border-radius: 50%;
  background-color: $discount-bubble-currency-color;
  width: 1em;
  height: 1em;

  @media (min-width: $screen-md-min) {
    font-size: 45px;
    margin-top: -20px;
  }

  .discount-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 11px;
    white-space: nowrap;
    color: #ffffff;

    @media (min-width: $screen-md-min) {
      font-size: 14px;
    }
  }

  .discount-bubble-bg {
    background-color: $discount-bubble-currency-color;
  }

  &--currency {
    border-radius: 0;
    position: absolute;
    z-index: 10;
    bottom: 40px;
    left: 10px;
    font-size: 14px;
    padding: 0 12px;
    font-weight: 700;

    @media (max-width: $screen-xs-max) {
      left: 5px;
      bottom: auto;
      top: 20px;
    }

    .discount-label--currency {
      color: #fff;
    }

    .currency-discount--desktop {
      display: none;

      @media (min-width: $screen-sm-min) {
        display: inline-block;
      }
    }

    .currency-discount--mobile {
      display: none;

      @media (max-width: $screen-xs-max) {
        display: inline-block;
      }
    }
  }

  &__product-ad {
    font-size: 20px;
    bottom: 0;
    left: 20px;

    @media (max-width: $screen-xs-max) {
      left: 5px;
      bottom: auto;
      top: 20px;
    }

    @media (min-width: $screen-sm-min) {
      font-size: 22px;
    }

    &--compact {
      font-size: 16px;
      bottom: -25px;
      left: 0;
      width: max-content;
      margin: auto;

      @media (max-width: $screen-xs-max) {
        left: 5px;
        bottom: auto;
        top: 20px;
      }

      @media (min-width: $screen-sm-min) {
        font-size: 20px;
        bottom: 0;
      }
    }
  }

  &--rich {
    left: 0;
    bottom: 0;
    font-size: 14px;
    background-color: $discount-bubble-currency-color;

    @media (max-width: $screen-xs-max) {
      left: 5px;
      bottom: auto;
      top: 20px;
    }
  }
}

.club-price-rectangle {
  position: absolute;
  padding: 0;
  display: grid;
  right: 10px;
  bottom: unset;
  left: unset;
  top: 40%;

  @media (max-width: $screen-xs-max) {
    right: 5px;
    top: 40%;
  }
}

.club-price-currency {
  border-radius: 0;
  position: absolute;
  z-index: 10;
  bottom: 40px;
  left: 10px;
  font-size: 14px;
  padding: 0 12px;
  font-weight: 700;
  margin-top: -16px;

  @media (min-width: $screen-md-min) {
    margin-top: -20px;
  }

  @media (max-width: $screen-xs-max) {
    left: 5px;
    bottom: auto;
    top: 20px;
  }
}

.club-price-discount {
  font-family: "Barlow Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
  background: $club-price-currency-color;
  color: black;
  text-align: center;

  &:nth-child(1) {
    @media (max-width: $screen-xs-max) {
      font-size: 16px;
    }
  }

  &:nth-child(2) {
    font-size: 21px;

    @media (max-width: $screen-xs-max) {
      margin-top: -5px;
      font-size: 18px;
    }
  }
}

.club-price-discount-expires {
  color: var(--Content-content-secondary, #565656);
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.club-price-discount-lists {
  @media (max-width: $screen-md-min) {
    font-size: 16px;
    padding: 0 10px;

    &:nth-child(2) {
      font-size: 18px;
    }
  }
}

.prodpage-discount-bubble {
  position: absolute;
  z-index: 10;
  top: 40px;
  right: 15px;
  font-size: 56px;
  border-radius: 50%;
  background-color: $discount-bubble-currency-color;
  width: 1em;
  height: 1em;

  @media (min-width: $screen-sm-min) {
    font-size: 64px;
  }

  @media (min-width: $screen-md-min) {
    font-size: 72px;
  }

  .prodpage-discount-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 18px;
    white-space: nowrap;
    color: #ffffff;

    @media (min-width: $screen-sm-min) {
      font-size: 20px;
    }

    @media (min-width: $screen-md-min) {
      font-size: 22px;
    }
  }
}

.list {
  .discount-bubble--currency {
    @media (min-width: $screen-sm-min) {
      bottom: 15px;
      left: 10px;
      top: unset;
    }
  }
}

.discount-bubble-bg {
  background-color: $discount-bubble-currency-color;
}

.prodpage-club-price-rectangle {
  display: grid;
  padding: 0;
  cursor: pointer;

  @media (min-width: $screen-sm-min) {
    padding: 0;
  }
}

.prodpage-discount-bubble--currency {
  border-radius: 0;
  position: absolute;
  z-index: 10;
  top: 40px;
  right: 0;
  font-size: 18px;
  padding: 0 12px;
  font-weight: 700;

  @media (min-width: $screen-sm-min) {
    font-size: 22px;
  }

  .prodpage-discount-label {
    color: #fff;
  }

  .currency-discount--desktop {
    display: none;

    @media (min-width: $screen-sm-min) {
      display: inline-block;
    }
  }

  .currency-discount--mobile {
    display: none;

    @media (max-width: $screen-xs-max) {
      display: inline-block;
    }
  }

  .club-price-discount {
    font-family: "Barlow Condensed", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    padding: 0 15px;
    display: inline-block;
    background: $club-price-currency-color;
    color: black;
    text-align: center;
    cursor: pointer;

    @media (max-width: $screen-xs-max) {
      padding: 0 5px;
      font-size: 13px;
    }

    &.discount-title {
      padding-top: 6px;

      @media (max-width: $screen-xs-max) {
        padding-top: 0;
        font-size: 13px;
      }
    }

    &.discount-price {
      font-weight: bold;
      padding-bottom: 6px;

      @media (max-width: $screen-xs-max) {
        margin-top: -5px;
        font-size: 18px;
      }
    }

    &.discount-button {
      font-weight: 400;
      font-size: 16px;
      background: #74daea;
      line-height: 19px;
      padding: 2px 0 4px 0;
      display: none;

      @media (max-width: $screen-xs-max) {
        font-size: 13px;
        padding: 2px 8px;
      }
    }
  }
}