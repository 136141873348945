$link-background-color: #f2f3f7 !default;
$link-background-hover-color: #eaebef !default;
$vertical-menu-header-font-weight: bold !default;
$text-color-game-mode: #ffffff !default;
$link-background-color-game-mode: #333333 !default;
$link-background-hover-color-game-mode: #808080 !default;

.multi-level-vertical-menu {
    ul.listing {
        padding: 0;
        list-style-type: none;

        li {
            margin-bottom: 2px;

            a {
                color: $text-color-default-medium;
                padding: 6px 0 6px 10px;
                background: $link-background-color;
                display: flex;
                justify-content: space-between;
                border-radius: 3px;
                position: relative;
                font-weight: normal;
                text-decoration: none;
                padding-left: 10px;

                &.active, &:hover, &:focus {
                    background: $link-background-hover-color;
                }

                &:focus {
                    .text {
                        text-decoration: underline;
                    }
                }

                .icon {
                    float: right;
                    margin-right: 5px;
                    border-radius: 2px;
                }
            }
        }
    }
}

.game-mode-layout .multi-level-vertical-menu {
    ul.listing li a {
        color: $text-color-game-mode;
        background: $link-background-color-game-mode;

        &.active, &:hover, &:focus {
            background: $link-background-hover-color-game-mode;
        }
    }
}

@media (min-width: $screen-md-min) {
    .responsive-menu-wrapper {
        float: left;
    }

    .vertical-menu {
        width: 179px;
        z-index: 2;
        position: relative;

        .vertical-menu-header {
            text-transform: uppercase;
            margin-bottom: 10px;
            font-weight: $vertical-menu-header-font-weight;
            font-size: $menu-font-size-default;
            margin-top: 0;
            display: block;
            line-height: 1.2em;
        }
    }
}