.rich-navigation {
    &-container {
        flex: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        font-size: 14px;
        line-height: 24px;
        margin: 5px 0;
        overflow: hidden;

        @media (max-width: $screen-md) {
            margin: 0;
            flex-wrap: nowrap;
            justify-content: initial;
            -ms-overflow-style: none;
            scroll-snap-type: x mandatory;
            overflow-x: scroll;
            margin-left: 12px;
            margin-right: 12px;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &-item {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        margin: 16px 8px;
        color: inherit;
        align-items: center;
        text-decoration: none;

        &__wrapper {
            @media (max-width: $screen-md) {
                display: flex;
                flex: 1 0 auto;
                scroll-snap-align: start;
            }
        }

        &__title {
            margin-top: 8px;
            text-align: center;

            &.loading {
                width: 150%;
                line-height: 14px;
                background: #000;
                opacity: 0.1;
            }
        }
    }

    &__filter {
        width: 40px;
        height: 100%;
        position: absolute;
        /* top: 0; */
        z-index: 1;
        background: white;
        opacity: 0.5;
        right: -129px;
    }

    &__arrow {
        width: 14px;
        height: 14px;
        background: transparent;
        border-color: #000;
        border-width: 0 0 3px 3px;
        border-style: solid;
        position: absolute;
        z-index: 1;
        cursor: pointer;
        padding: 0;
        top: 50%;
        transform: translate(0, -50%) rotate(-135deg);
        @media (min-width: $screen-md) {
            display: none;
        }

        &:focus {
            outline: none;
        }

        &--left {
            transform: translate(0, -50%) rotate(45deg);
            left: 7px;
        }

        &--right {
            right: 7px;
        }
    }
}
