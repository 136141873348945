#web-game-section {
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    min-height: 43px;
    transition: min-height 1.5s linear;

    #game-container {
        z-index: 1;
    }

    @media (min-width: $screen-sm-min) {
        &::after {
            content: "";
            background-color: #fff;
            background-image: url(/marketingmedia/66002/blackfridayrunnerbackground.jpg);
            opacity: 0;
            top: 0;
            right: 7.5px;
            height: 100%;
            width: calc(100% - 7.5px * 2);
            position: absolute;
            z-index: 0;
            transition: opacity 1.5s linear, background-color 1.5s linear;
        }
    }

    &.game-on {
        min-height: 384px;

        @media (min-width: $screen-sm-min) {
            min-height: 384px + 32px * 2;
            padding: 32px 0;

            &::after {
                background-color: #222;
                opacity: 1;
            }
        }
    }

    .btn-large.primary.play-game {
        color: #f5f5f5;
        background: #545454;
        border-bottom-color: #222;
    }
}
