﻿.brands-list {
    margin: 0 10px;

    h1 {
        margin-bottom: 1em;
    }

    a {
        display: block;
        font-size: 18px;
        line-height: 2em;
    }

    %brand-container {
        display: flex;
        flex-wrap: wrap;
        padding: 0 15px;
        list-style: none;
    }

    .brand-list-keys-container {
        @extend %brand-container;
        flex-direction: row;

        .brand-list-keys-group {
            padding-right: 10px;

            a {
                font: 18px Arial, sans-serif;
            }
        }
    }

    .brand-list-container {
        @extend %brand-container;
        flex-direction: column;

        .brand-list-group {
            padding: 0;

            h2 {
                border-bottom: 1px solid #b6afa9;
            }
        }
    }

    @media (min-width: $screen-sm) {
        a {
            font-size: 16px;
        }

        .brand-list-container {
            flex-direction: row;
            justify-content: space-between;

            .brand-list-group {
                width: 45%;
                flex: 0 0 auto;
            }
        }
    }

    @media (min-width: $screen-md) {
        a {
            font-size: inherit;
            line-height: inherit;
        }

        .brand-list-container {
            h2 {
                font-size: 24px;
            }

            .brand-list-group {
                .brand-list-group-items {
                    column-count: 2;
                    column-gap: 40px;
                    padding: 5px 0 20px 0px;
                }
            }
        }
    }
}