@mixin xs-padding() {
    @media(max-width: $screen-xs-max) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.article-page {
    .image-caption {
        font-size: 13px;
        padding: 5px;
    }

    @media(max-width: $screen-xs-max) {
        margin-top: 10px;
    }

    .article-page-image {
        img {
            max-width: 100%;
            height: auto;
        }
    }

    .article-page-header-centered {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    header {
        h1 {
            font-family: Arial;
            font-size: 43px;

            @media(max-width: $screen-xs-max) {
                font-size: 35px;
            }
        }

        @include xs-padding();
    }

    .article-page-published {
        border-bottom: 1px solid #e6e6e6;
        color: #4a4a4a;
        font-family: Arial;
        font-size: 14px;

        @include xs-padding();
    }

    .article-page-content {
        font-family: Arial;
        margin-top: 20px;

        p {
            font-size: 16px;
            line-height: 25px;
        }

        blockquote {
            border-left: 4px solid #4D6470;
            display: block;
            font-size: 15px;
            line-height: 25px;
            margin-left: 65px;
            padding-left: 10px;
        }

        .banner-image img {
            margin: initial;
        }
    }
}
