.video-wrapper {
    width: 100%;
}

.youtube-video-with-cover img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    object-fit: cover
}

.youtube-video-with-cover iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    opacity: 0;
    transition: opacity 2.4s ease 0s;
}

.youtube-video-with-cover.loaded iframe {
    opacity: 1;
}

.youtube-video-with-cover span.icon {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 7em;
    opacity: .8;
    transition: opacity 1.5s ease 0s;
}

.youtube-video-with-cover.loaded span.icon {
    opacity: 0;
}

.swiper-slide .youtube-video-with-cover img {
    position: static;
    width: 70% !important;
}