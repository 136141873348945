@use "sass:math";

.list {
    .product-list-item {
        justify-content: flex-start;

        .image-container {
            @media (min-width: $screen-sm-min) {
                .image-wrapper {
                    padding: 0;
                }
            }
        }

        .content-block {
            min-height: 160px;

            @media (min-width: $screen-sm-min) {
                flex-direction: row;
            }

            @media (min-width: $screen-md-min) {
                width: 622px;
                justify-content: flex-end;
            }

            @media (min-width: $screen-lg-min) {
                width: 830px;
            }
        }

        .text-container {
            @media (min-width: $screen-sm-min) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 0;
                white-space: normal;
                flex: 0 1 430px;
                order: 1;
                margin-right: auto;
            }

            @media (min-width: $screen-md-min) {
                flex-basis: 381px;

                .product-energy-logo {
                    display: none;
                }
            }

            @media (min-width: $screen-lg-min) {
                flex-basis: 589px;
            }
        }

        .box-bottom {
            @media (min-width: $screen-sm-min) {
                flex-direction: column;
                min-width: 150px;
                order: 3;
                justify-content: flex-start;
                margin-left: auto;
            }

            @media (min-width: $screen-md-min) {
                padding-top: 0;

                .product-energy-logo {
                    display: flex;
                }
            }

            &.product-list-item--without-quantity-picker {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .price-on-request-button__container {
                align-items: center;
                justify-content: flex-start;
            }

            .product-energy-logo {
                white-space: nowrap;
                padding-bottom: 0;
                margin-bottom: 10px;
                flex-direction: row-reverse;

                .product-energy-logo-details {
                    margin-left: 0;
                    margin-right: math.div($product-box-spacing, 2);
                }
            }
        }

        .price-wrapper {
            @media (min-width: $screen-sm-min) {
                flex-direction: column;
                flex-wrap: nowrap;
                min-width: 120px;
                order: 2;
                white-space: nowrap;
                margin-left: $product-box-spacing;
                margin-right: $product-box-spacing;

                .product-price-before {
                    margin-bottom: 0;
                    padding-bottom: $product-box-spacing;
                }

                .product-price-subscription {
                    padding-top: $product-box-spacing;
                }
            }
        }

        .text-content {
            margin-bottom: 10px;
        }

        @media (min-width: $screen-sm-min) {
            .review {
                vertical-align: middle;
            }

            .delivery,
            .stockstatus,
            .product-seller {
                text-align: left;
                left: 158px;
                width: 110px;
                padding: 0;
            }

            .buy-button {
                flex-direction: column;
                flex-wrap: nowrap;
                align-self: flex-start;

                .quantity-picker {
                    display: flex;
                    white-space: nowrap;
                    margin-bottom: $product-box-spacing;
                }

            }

            .buy-button, .subscribe-product__redirect, .price-on-request-button__container {
                .btn-small {
                    height: 34px;
                }

                &.align-right    {
                    align-self: flex-end;
                }
            }

            .club-price-rectangle {
                top: unset;
                bottom: 5px;
                left: 5px;
                right: unset;
            }
        }

        @media (min-width: $screen-md-min) {

            .delivery {
                padding-left: 2px;
            }

            .text-content {
                margin-bottom: 0;
            }

            .review {
                margin-bottom: 0;
            }

            .stockstatus, .delivery {
                bottom: 39px;
                width: 300px;
            }

            .product-seller {
                bottom: 55px;
                width: 381px;
                padding-left: 2px;
            }

            .product-seller ~ .stockstatus, .product-seller ~ .delivery {
                bottom: 74px;
            }

            .product-data {
                display: block;
                margin-top: 24px;
            }

            .product-data.display-seller {
                margin-top: 60px;
            }
        }

        @media (min-width: $screen-lg-min) {
            .stockstatus, .delivery, .product-seller {
                width: 500px;
            }
        }
    }
}
