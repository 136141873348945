$breadcrumb-font-size: 12px !default;

.breadcrumbs {
    @include clearfix();
    margin: -12px 0 12px 0;
    padding: 6px 0;
    border-bottom: 1px solid $border-color;
    font-size: $breadcrumb-font-size;

    @media (max-width: $screen-xs-max) {
        margin-bottom: 0;
        padding-left: $grid-gutter-width / 2;
    }

    .you-are-here-label {
        display: none;
        float: left;
        font-weight: bold;
        font-size: $breadcrumb-font-size;
        margin: 0;
        margin-right: 6px;
        line-height: $line-height-base;

        @media (min-width: $screen-md-min) {
            display: inline-block;
        }
    }

    .icon {
        vertical-align: top;
        margin-right: -2px;
    }

    .breadcrumb-items {
        .breadcrumb-item-wrapper {
            float: left;
            margin-right: 2px;
        }

        .breadcrumb-item-wrapper:nth-child(1) {
            .icon {
                display: none;
            }
        }

        .breadcrumb-item-link {
            text-overflow: ellipsis;
            max-width: 500px;
            display: inline-block;
            white-space: nowrap;

            &:hover {
                text-decoration: underline;
            }
        }

        a.breadcrumb-item-link:hover {
            color: #0670A3;
        }
    }

    .current-location {
        display: block;
        max-width: 40ch;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &.is-current-location-visible {
            display: block;
        }
    }
}