@mixin setFonts($font-size) {
    $line-height: 1.51;
    font-size: $font-size;
    line-height: $line-height;
}

.row {
    .article-banner {
        border: 1px solid #dedede;
        color: #222;
        width: 100%;
        padding-bottom: 26px;

        @media (max-width: $screen-xs-max) {
            border: none;
        }

        .article-banner-image {
            width: 100%;

            img {
                opacity: 0;
                width: 100%;
                height: auto;
            }

            &.object-fit-fallback {
                background-position: center center;
                background-size: cover;
                color: red;
            }
        }

        .heading {
            padding: 10px 26px;

            @media (max-width: $screen-xs-max) {
                margin: 10px 0;
                padding: 0;
            }
        }

        .preamble {
            padding: 10px 26px 0;
            @include setFonts(16px);

            @media (max-width: $screen-xs-max) {
                font-size: 14px;
                line-height: 19px;
                margin: 10px 0;
                padding: 0;
            }
        }
    }

    div:first-child:nth-last-child(1),
    div:first-child:nth-last-child(1) ~ div,
    div:first-child:nth-last-child(2),
    div:first-child:nth-last-child(2) ~ div {
        .article-banner {
            .article-banner-image {
                height: 300px;

                img {
                    height: 300px;
                    object-fit: cover;
                }
            }

            .heading {
                @include setFonts(35px);

                @media (max-width: $screen-xs-max) {
                    font-size: 24px;
                    padding-bottom: 0;
                }
            }
        }
    }

    div:first-child:nth-last-child(3),
    div:first-child:nth-last-child(3) ~ div,
    div:first-child:nth-last-child(4),
    div:first-child:nth-last-child(4) ~ div {
        .article-banner {
            .article-banner-image {
                height: 250px;
                margin-bottom: 10px;

                img {
                    height: 250px;
                    object-fit: cover;
                }
            }

            .heading {
                @include setFonts(30px);
            }
        }
    }
}
