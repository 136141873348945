.expandable-rte {
  position: relative;
  width: 100%;

  img {
    max-width: 100%;
  }

  table {
    @media (max-width: $screen-md-min) {
      width: 100%;
    }
  }

  @media (max-width: $screen-md-min) {
    padding: 0 10px;
  }

  .expandable-rte-wrapper {
    padding: 0;
  }

  .expandable-rte-content-show-more-link {
    position: absolute;
    cursor: pointer;
    font-size: 13px;
    color: #0074bd;

    &:hover {
      text-decoration: underline;
    }

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 32 32'%3E%3Cpath fill='%230074bd' d='M 8.359375 23.109375 C 8.359375 22.871094 8.449219 22.660156 8.625 22.484375 L 16 15.109375 L 8.625 7.734375 C 8.449219 7.558594 8.359375 7.351563 8.359375 7.109375 C 8.359375 6.871094 8.449219 6.660156 8.625 6.484375 L 10.929688 4.179688 C 11.105469 4.003906 11.316406 3.917969 11.554688 3.917969 C 11.796875 3.917969 12.003906 4.003906 12.179688 4.179688 L 22.484375 14.484375 C 22.660156 14.660156 22.75 14.871094 22.75 15.109375 C 22.75 15.351563 22.660156 15.558594 22.484375 15.734375 L 12.179688 26.042969 C 12.003906 26.21875 11.796875 26.304688 11.554688 26.304688 C 11.316406 26.304688 11.105469 26.21875 10.929688 26.042969 L 8.625 23.734375 C 8.449219 23.558594 8.359375 23.351563 8.359375 23.109375 Z '/%3E%3C/svg%3E");
      transform: rotate(90deg);
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      top: 2px;
      right: -15px;
    }

    &.open::before {
      transform: rotate(270deg);
      top: 0;
    }
  }

  .expandable-rte-content {
    display: block;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      pointer-events: none;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 30px;
      background: linear-gradient(
        to top,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &.open {
      height: fit-content;

      &::before {
        background: transparent;
      }
    }
  }
}
